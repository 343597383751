import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import {appSnackbarRef} from "../../App";
import MapContainer from "damap/dist/esm/ol-map/containers/MapContainer";
import DrmViewModel from "../view_models/DrmViewModel";
import MapVM from "damap/dist/esm/ol-map/models/MapVM";
import Map from "ol/Map";
import { transform } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OverlayVectorLayer from "damap/dist/esm/ol-map/layers/overlay_layers/OverlayVectorLayer";
import {AppAPIs} from "../../Api";
import XYZLayer, {IXYZLayerInfo} from "damap/dist/esm/ol-map/layers/overlay_layers/XYZLayer";
import {Slider, Box, Typography, Button} from "@mui/material";
import {Icon, Style} from "ol/style";
import ExposureAnalysisDialogBox from "../components/RiskAssessment/ExposureAnalysisDialogBox";


const RiskAssessmentResponse = () => {
    const {request_id} = useParams(); // Get the request_id from the URL
    const riskMapViewRef = useRef(null); // Ref for the MapContainer
    const mapUUID: string = "77d677084f3e11eebc0eacde48001122";
    const api: MapApi = new MapApi(appSnackbarRef); // Assuming appSnackbarRef is defined elsewhere in your app
    const [mapVM, setMapVM] = useState<MapVM>();
    const [olMap, setOlMap] = useState<Map>();
    const [floodLayer, setFloodLayer] = useState<OverlayVectorLayer>();
    const [surfaceList, setSurfaceList] = useState<{ name: string, uuid: string }[]>([]);
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [selectedDate, setSelectedDate] = useState<number | null>(null);
    const [xyzLayers, setXyzLayers] = useState<{ [key: string]: XYZLayer }>({}); // Store layers by their UUID
    const [pointLayer, setPointLayer] = useState<VectorLayer<VectorSource>>(null);
    const [riskAssessmentDialogOpen, setRiskAssessmentDialogOpen] = useState(false);
    const [selectedPoint, setSelectedPoint] = useState(null); // To store clicked point coordinates

    // Initialize the MapVM object and set it in state
    useEffect(() => {
        if (!mapVM) {
            const mvm = riskMapViewRef?.current?.getMapVM();
            if (mvm) {
                setMapVM(mvm);
            }
        }
    }, [riskMapViewRef?.current?.getMapVM()]);

    // Initialize the OpenLayers map when the MapVM is ready
    useEffect(() => {
        if (!olMap && mapVM) {
            const mapInterval = setInterval(() => {
                const map = mapVM.getMap();
                if (map) {
                    setOlMap(map);
                    clearInterval(mapInterval); // Stop the interval once the map is set
                }
            }, 100); // Check every 100ms

            return () => clearInterval(mapInterval); // Clean up interval on unmount
        }
    }, [olMap, mapVM]);

    // Create the floodLayer when olMap is available
    useEffect(() => {
        if (olMap) {
            const uuid = MapVM.generateUUID();
            const layer = DrmViewModel.createFloodLayer(mapVM, uuid);
            setFloodLayer(layer);
        }
    }, [olMap]);

    // Fetch and display flood hazard data (GeoJSON)
    useEffect(() => {
        if (floodLayer && request_id) {
            (async () => {
                try {
                    mapVM.getMapLoadingRef().current?.openIsLoading();
                    api.get(AppAPIs.DRM_FLOOD_HAZARD_RESPONSE, {request_id: request_id})
                        .then(payload => {
                            if (payload) {
                                const geojson = payload.geojson;
                                floodLayer?.addGeojsonFeature(geojson, true);
                                floodLayer?.zoomToFeatures();
                                setSurfaceList(payload.surface_list.map(surface => ({
                                    name: surface,
                                    uuid: MapVM.generateUUID()
                                })));
                                const dateRange = payload.date_range;
                                setDateRange([new Date(dateRange["min"]), new Date(dateRange["max"])]);
                                setSelectedDate(new Date(dateRange["min"]).getTime());
                            }
                        }).catch(error => {
                        console.error("Error fetching GeoJSON data:", error);
                        appSnackbarRef.current?.show("Failed to fetch data");
                    }).finally(() => {
                        mapVM.getMapLoadingRef().current?.closeIsLoading();
                    });
                } catch (error) {
                    appSnackbarRef.current?.show("Request Id or map is not available");
                    console.error("Error in fetchGeoJSON:", error);
                }
            })();
        }
    }, [floodLayer, request_id]);

    // Add XYZ layers for each surface
    useEffect(() => {
        if (surfaceList.length > 0 && dateRange.length === 2) {
            const newLayers = {...xyzLayers};
            surfaceList.forEach((surface) => {
                if (!newLayers[surface.uuid]) {
                    const date = new Date(selectedDate || dateRange[0]).toISOString().split('T')[0];
                    const url = MapApi.getURL(AppAPIs.DRM_HAZARD_SURFACE, {
                        request_id: request_id,
                        date: date,
                        surface_name: surface.name
                    });
                    const legend_url = MapApi.getURL(AppAPIs.DRM_HAZARD_LAYER_LEGEND, {
                        request_id: request_id,
                        surface_name: surface.name
                    });
                    const info: IXYZLayerInfo = {
                        title: surface.name,
                        url: url,
                        uuid: surface.uuid,
                        visible: true,
                        legendURL: legend_url,
                    };

                    const newXyzLayer = new XYZLayer(info, mapVM);
                    mapVM.getMap().addLayer(newXyzLayer.olLayer);
                    newLayers[surface.uuid] = newXyzLayer; // Store the layer in state
                }
            });
            setXyzLayers(newLayers); // Update the state with new layers
        }
    }, [surfaceList, dateRange, selectedDate]);

    // Handle the date slider change
    const handleDateChange = (event, newValue) => {
        setSelectedDate(newValue);
        const date = new Date(newValue).toISOString().split('T')[0];
        Object.values(xyzLayers).forEach((layer) => {
            const surface = surfaceList.find(s => s.uuid === layer.uuid);
            if (surface) {
                const url = MapApi.getURL(AppAPIs.DRM_HAZARD_SURFACE, {
                    request_id: request_id,
                    date: date,
                    surface_name: surface.name
                });
                layer.updateSourceURL(url);
            }
        });
        olMap.render(); // Re-render the map to reflect changes
    };

    const createLayerStyle = () => {
        return new Style({
            image: new Icon({
                anchor: [0.5, 1], // Adjust anchor point to position the icon
                src: 'https://cdn0.iconfinder.com/data/icons/google-material-design-3-0/48/ic_location_on_48px-512.png', // Path to the location icon (SVG/PNG)
                scale: 0.05, // Adjust the size of the icon as needed
            }),
        });
    };


    // Initialize the point layer when the olMap is ready
    useEffect(() => {
        if (olMap && !pointLayer) {
            const newPointLayer = new VectorLayer({
                source: new VectorSource(),
                style: createLayerStyle(),
            });
            olMap.addLayer(newPointLayer);
            setPointLayer(newPointLayer);
        }
    }, [olMap, pointLayer]);

    // Handle the click event to mark a point on the map
    const handleMarkPoint = () => {
        appSnackbarRef.current?.show("Click a point on the map to calculate element at risk");
        const map = olMap;

        const handleMapClick = (event) => {
            const clickedCoordinate = map.getCoordinateFromPixel(event.pixel);

            if (pointLayer && pointLayer.getSource()) {
                // Clear previous points from the layer
                pointLayer.getSource().clear();

                // Create a new point feature
                const pointFeature = new Feature({
                    geometry: new Point(clickedCoordinate),
                });

                // Add the new point to the layer
                pointLayer.getSource().addFeature(pointFeature);

                // Convert from map projection (EPSG:3857) to lat/long (EPSG:4326)
                const latLong = transform(clickedCoordinate, 'EPSG:3857', 'EPSG:4326');

                // Open the dialog box and pass the lat/long coordinates
                setSelectedPoint({
                    x: latLong[0],
                    y: latLong[1],
                });

                setRiskAssessmentDialogOpen(true); // Open the dialog box
                // Remove the event listener after marking the point
                map.un('click', handleMapClick);
            } else {
                console.error("pointLayer or its source is undefined");
            }
        };

        // Add click event listener to map
        map.on('click', handleMapClick);
    };

    return (
        <>
            <MapContainer ref={riskMapViewRef} uuid={mapUUID} title={"Flood Hazard Assessment"}>
                <Button variant={"contained"} onClick={handleMarkPoint}>Exposure Analysis</Button>
                {dateRange.length > 0 && selectedDate !== null && (
                    <Typography gutterBottom>
                        Date: {new Date(selectedDate).toISOString().split('T')[0]}
                    </Typography>
                )}
            </MapContainer>
            {dateRange.length > 0 && selectedDate !== null && (
                <Box
                    sx={{
                        position: "absolute",
                        bottom: 30,
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "50%",
                        padding: 2,
                        bgcolor: "rgba(255, 255, 255, 0.8)"
                    }}
                >
                    <Slider
                        value={selectedDate}
                        min={dateRange[0].getTime()}
                        max={dateRange[1].getTime()}
                        onChange={handleDateChange}
                        step={24 * 60 * 60 * 1000} // Step by day in milliseconds
                        valueLabelDisplay="auto"
                        valueLabelFormat={(value) => new Date(value).toISOString().split('T')[0]}
                        aria-labelledby="date-slider"
                    />
                </Box>
            )}
            {selectedPoint && (
                <ExposureAnalysisDialogBox
                    open={riskAssessmentDialogOpen}
                    onClose={() => setRiskAssessmentDialogOpen(false)}
                    // onSubmit={handleDialogSubmit}
                    mapVM={mapVM}
                    requestId={request_id}
                    lon={selectedPoint.x}
                    lat={selectedPoint.y}
                />
            )}
        </>
    );
};

export default RiskAssessmentResponse;
