export const AppAPIs = Object.freeze({
    FF_DISCHARGE_DATE_RANGE: "api/ff/pmd_discharge_range",
    FF_DISCHARGE_VALUE: "api/ff/pmd_discharge/{date}/",
    FF_REACH_NAME: "api/ff/reach_name/",
    FF_REACH_GEOM: "api/ff/reach_geom/{reach_name}/",
    FF_CATCHMENT_NAME: "api/ff/catchment_name/",
    FF_CATCHMENT_GEOM: "api/ff/catchment_geom/{catchment_id}/",
    FF_CATCHMENT_STATS: "api/ff/catchment_stats/{catchment_id}/",
    FF_CATCHMENT_FORECAST: "api/ff/catchment_pr_forecast/{catchment_id}/",
    FF_REACH_GAUGE_INFO: "api/ff/reach_gauge_info/{reach_name}/",
    FF_FLUVIAL_FLOOD_INUNDATION: "api/ff/calculate_fluvial_flood_inundation/{reach_name}/{up_val}/{down_val}/",
    FF_PLUVIAL_FLOOD_INUNDATION: "api/ff/calculate_pluvial_flood_inundation/{catchment_id}/{pr_value}/",
    FF_AFFECTED_POP: "api/ff/get_affected_population/{map_id}",
    FF_PMD_HYDRO_GAUGES_GEOJSON: "api/ff/pmd_hydro_gauges_geojson",
    FF_AFFECTED_DISTRICT_DETAILS: "api/ff/get_affected_districts_details/{map_Id}",
    FF_AFFECTED_TEHSIL_DETAILS: "api/ff/get_affected_tehsils_details/{map_Id}",
    FF_DISTRICT_NAME: "api/ff/district_name/",
    FF_DISTRICT_GEOM: "api/ff/district_geom/{district_id}",
    FF_DISTRICT_FORECAST: "api/ff/district_forecast/{district_id}",
    FF_DISTRICT_FLOOD_INUNDATION: "api/ff/calculate_district_flood/{district_id}/{pr_val}/{up_val}/{down_val}/",

    HA_GFC_CITIES: "api/ha/gfc_cities_geojson/",
    HA_GFC_CITIES_HEATWAVE_FORECAST: "api/ha/gfc_city_heat_index_forecast/{city_id}/",

    DRM_LOGIN_PUBLIC: "api/drm/public_login/{phone_no}/",
    DRM_HAZARD_REQUEST: "api/drm/process_hazard_request/{phone_no}/{request_type}",
    DRM_HAZARD_REQUEST_LIST: "api/drm/hazard_request_list/{phone_no}/",
    DRM_HAZARD_CONFIRM_REQUEST: "api/drm/confirm_hazard_request/{request_id}/{otp}",
    DRM_FLOOD_HAZARD_RESPONSE: "api/drm/flood_hazard_response/{request_id}/",
    DRM_EXECUTE_HAZARD_REQUEST: "api/drm/execute_hazard_request/{request_id}/",
    DRM_HAZARD_SURFACE: "api/drm/hazard_surfaces/{request_id}/{date}/{surface_name}/{z}/{x}/{y}/",
    DRM_HAZARD_LAYER_LEGEND: "api/drm/hazard_layer_legend/{request_id}/{surface_name}/",
    DRM_SEND_RESPONSE_SMS: "api/drm/send_response_sms/{request_id}/",
    DRM_ELEMENT_AT_RISK: "api/drm/element_at_risk/{request_id}/{lon}/{lat}/{buffer_in_meter}/",
    MANAGE_DRM_ALL_RISK_ASSESSMENT: "api/manage_drm/all_risk_assessment",
    MANAGE_DELETE_HAZARD_MODELING_REQUEST: "api/manage_drm/delete_hazard_modeling_request/{request_id}/"

});
