import React from "react";
import {Button, Slider} from "@mui/material";
import MapVM from "damap/dist/esm/ol-map/models/MapVM";
import {AppAPIs} from "../../../../Api";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import {appSnackbarRef} from "../../../../App";
import DrmViewModel from "../../../view_models/DrmViewModel";

import OverlayVectorLayer from "damap/dist/esm/ol-map/layers/overlay_layers/OverlayVectorLayer";

import {useDispatch, useSelector} from "react-redux";
import {floodActions} from "../../../../store/slice/flood";
import {store} from "../../../../store/store";
import FloodInfoTabs from "../FloodInfoTabs";

interface IProps {
    mapVM: MapVM;
    setDialogActions: any;
}

export const pluvialFloodLayerUUID = MapVM.generateUUID();

const calculateFlood = (mapVM, catchment_id) => {
    // const currentSliderValue = useSelector((state:any)=>state.flood.selectedPRValue)
    const currentSliderValue = store.getState().flood.selectedPRValue
    const basinName = store.getState().flood.displayName

    mapVM.getDialogBoxRef()?.current?.closeDialog();
    mapVM.getMapLoadingRef()?.current?.openIsLoading();
    mapVM.getAttributeTableRef()?.current?.getToolbarRef();
    const api = new MapApi(appSnackbarRef);
    api.get(AppAPIs.FF_PLUVIAL_FLOOD_INUNDATION, {
        catchment_id: catchment_id,
        pr_value: currentSliderValue
    })
        .then((payload) => {
            console.log("pluvial payload", payload)
            if (payload) {
                let floodLayer;

                if (!(pluvialFloodLayerUUID in mapVM.overlayLayers)) {
                    floodLayer = DrmViewModel.createFloodLayer(mapVM, pluvialFloodLayerUUID, false);
                } else {
                    floodLayer = mapVM.overlayLayers[pluvialFloodLayerUUID] as OverlayVectorLayer;
                    const source = floodLayer.olLayer.getSource();
                    console.log("source", source)
                    source.clear();
                    source.refresh();
                }
                floodLayer.addGeojsonFeature(payload.geojson, true);
                floodLayer.zoomToFeatures();
                store.dispatch(floodActions.setFloodMapID(payload.map_id))
                mapVM.getMapLoadingRef()?.current?.closeIsLoading();

                const rightDrawer = mapVM.getRightDrawerRef();
                const features = floodLayer.getFeatures();
                const affectedPopulation = features.reduce((accumulator: number, feature) => {
                    const properties = feature.getProperties();
                    return accumulator + parseFloat(properties["population"]);
                }, 0);
                const affectedLULC = payload.lulc_stats
                const buildingCounts = payload.building_count
                const contents = <React.Fragment>
                    <FloodInfoTabs
                        key={"flood-info-table"}
                        mapVM={mapVM}
                        affectedPopulation={affectedPopulation}
                        lulc={affectedLULC}
                        buildingCounts={buildingCounts}
                        displayName={basinName}
                        /> </React.Fragment>;
                rightDrawer?.current?.addContents("Flood Impact", contents);
                rightDrawer?.current?.openDrawer();
            }
        })
        .finally(() => {
            store.dispatch(floodActions.closeDialog())
            mapVM.getMapLoadingRef()?.current?.closeIsLoading();
        })
};
const PluvialFloodInputs = (props: IProps) => {
    const [prStats, setPRStats] = React.useState([]);
    const [prRp, setPRrp] = React.useState([]);
    const [prForecast, setPrForecast] = React.useState([]);
    // const [sliderValue, setSliderValue] = React.useState<number>(0);
    // const [minValue, setMinValue] = React.useState<number>(0);
    const minValue = 0
    const [maxValue, setMaxValue] = React.useState<number>(300);
    const api = new MapApi(appSnackbarRef);
    const dispatch = useDispatch()
    const sliderValue = useSelector((state: any) => state.flood.selectedPRValue)
    const catchment_id = useSelector((state: any) => state.flood.aoi_key);

    // const sliderRef = React.useRef()


    React.useEffect(() => {
        api.get(AppAPIs.FF_CATCHMENT_STATS, {catchment_id: catchment_id})
            .then((payload) => {
                setPRStats(payload["stats"]);
                setPRrp(payload["rp"]);
                const initialResult = {maxOfMax: -Infinity, minOfMin: Infinity};
                const result = payload["stats"].reduce((acc, stat) => {
                    return {
                        maxOfMax: Math.max(acc.maxOfMax, stat.max),
                        minOfMin: Math.min(acc.minOfMin, stat.min)
                    };
                }, initialResult);

                // setSliderValue(result["minOfMin"]);
                dispatch(floodActions.setSelectedPRValue(result["minOfMin"]))
                const data = payload["rp"].find((item) => item.return_period === 10);
                data && setMaxValue(data["max_value"]);
            });
    }, []);

    React.useEffect(() => {
        const fetchPrecipitationForecast = async () => {
            api.get(AppAPIs.FF_CATCHMENT_FORECAST, {catchment_id: catchment_id})
                .then((payload) => {
                    setPrForecast(payload);
                    const initialResult = {maxRain: 0, maxSnow: 0};
                    const result = payload.reduce((acc, entry) => {
                        const rainValue = entry.rain_mm_h === "-" ? 0 : parseFloat(entry.rain_mm_h);
                        const snowValue = entry.snow_mm_h === "-" ? 0 : parseFloat(entry.snow_mm_h);
                        return {
                            maxRain: Math.max(acc.maxRain, rainValue),
                            maxSnow: Math.max(acc.maxSnow, snowValue)
                        };
                    }, initialResult);
                    if (result.maxRain !== 0) {
                        // setSliderValue(result.maxRain);
                        dispatch(floodActions.setSelectedPRValue(result.maxRain))
                    }
                });
            props.setDialogActions(<Button onClick={() => calculateFlood(props.mapVM, catchment_id)}>Flood
                Calculator</Button>);
        };
        fetchPrecipitationForecast();
    }, []);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        const value = Array.isArray(newValue) ? newValue[0] : newValue;
        const parsedValue = parseFloat(value.toFixed(2));
        // setSliderValue(parsedValue);
        dispatch(floodActions.setSelectedPRValue(parsedValue))
    };

    return (
        <React.Fragment>
            <div style={{overflowX: "auto", padding: "10px"}}>
                <h3>Select Precipitation Forecast</h3>
                <div style={{padding: "10px", paddingTop: "20px"}}>
                    <Slider
                        sx={{width: "100%"}}
                        value={sliderValue}
                        onChange={handleSliderChange}
                        aria-labelledby="slider-label"
                        valueLabelDisplay="auto"
                        step={(maxValue - minValue) / 100}
                        min={minValue}
                        max={maxValue}
                    />
                </div>
            </div>
            {prStats.length > 0 && (
                <div style={{overflowX: "auto", padding: "10px"}}>
                    <table className={"flow-table"}>
                        <thead>
                        <tr>
                            <th colSpan={7}><h3>Precipitation Stats</h3></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>Stats</th>
                            <th>Mean</th>
                            <th>Max</th>
                            <th>Max Year</th>
                            <th>Min</th>
                            <th>Min Year</th>
                            <th>Std Dev</th>
                        </tr>
                        {prStats.map((data, index) => (
                            <tr key={index}>
                                <td>{data.month}</td>
                                <td>{parseFloat(data.mean).toFixed(2)}</td>
                                <td>{parseFloat(data.max).toFixed(2)}</td>
                                <td>{data.max_year}</td>
                                <td>{parseFloat(data.min).toFixed(2)}</td>
                                <td>{data.min_year}</td>
                                <td>{parseFloat(data.std).toFixed(2)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            {prRp.length > 0 && (
                <div style={{overflowX: "auto", padding: "10px"}}>
                    <table className={"flow-table"}>
                        <thead>
                        <tr>
                            <th colSpan={7}><h3>Precipitation Return Period</h3></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {prRp.map((data) => (
                                <th key={`header-${data.return_period}`}>{`${data.return_period} years`}</th>
                            ))}
                        </tr>
                        <tr>
                            {prRp.map((data) => (
                                <td key={`max-value-${data.return_period}`}>{data.max_value.toFixed(2)}</td>
                            ))}
                        </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {prForecast.length > 0 && (
                <div style={{overflowX: "auto", padding: "10px"}}>
                    <table className={"flow-table"}>
                        <thead>
                        <tr>
                            <th colSpan={3}><h3>Precipitation Forecast</h3></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>Date</th>
                            <th>Rain (mm)</th>
                            <th>Snow (mm)</th>
                        </tr>
                        {prForecast.map((item, index) => (
                            <tr key={index}>
                                <td>{item.forecast_date}</td>
                                <td>{item.rain_mm}</td>
                                <td>{item.snow_mm}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </React.Fragment>
    );
}

export default PluvialFloodInputs;
