import * as React from "react";
import "./main.css"
import LoginIcon from '@mui/icons-material/Login';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import OTPDialog, {OTPDialogHandle} from "./modules/components/utilities/OTPDialog";
import {useRef, useState} from "react";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import {appSnackbarRef} from "./App";
import GeoJSON from "ol/format/GeoJSON";
import {AppAPIs} from "./Api";
import OTPInput from "./modules/components/utilities/otp/OTPInput";
import {useNavigate} from "react-router-dom";

const MainPage = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpInput, setOTPInput] = useState<boolean>(false)
    const [info, setInfo] = React.useState<string>()

    const otpDialogRef = useRef<OTPDialogHandle>();
    const mapApi = new MapApi(appSnackbarRef)
    const handleClick = () => {
        // otpDialogRef?.current && otpDialogRef?.current?.openDialog();
        setOpenDialog(true)
    }
    const handleCloseDialog = () => {
        setOpenDialog(false)
    }
    const handleSubmit = () => {
        if (phoneNumber) {
            // Make sure the geoJSON is passed as an object and not a string
            mapApi.get(AppAPIs.DRM_LOGIN_PUBLIC, {
                phone_no: phoneNumber,
            }).then((payload) => {
                if (payload?.info) {
                    // otpDialogRef?.current && otpDialogRef?.current?.openDialog(payload.request_id);
                    setInfo(payload.info)
                    setOTPInput(true)
                } else {
                    alert('Failed to submit the request.');
                }
            }).catch((error) => {
                console.error("Error submitting request:", error);
                alert('An error occurred while submitting the request.');
            })
        } else {
            alert('No AOI feature available or phone number is missing.');
        }
    };
    const navigate = useNavigate()
    const onChangeOTP = (otp) => {
        if (otp == info) {
            alert("welcome")
            setOpenDialog(false)
            navigate("public/risk_assessment_list/"+phoneNumber)
        }
    }

    return (
        <div className="main">
            <div className="overlap-group-wrapper">
                <div className="overlap-group">
                    <div className="shaded-background"/>
                    <img className="COMING-SOON" alt="Coming SOON" src="/static/img/COMING-SOON.svg"/>
                    <img className="wait" alt="Wait" src="/static/img/wait-1.gif"/>
                    <img className="pmd-icon" alt="PMD icon" src="/static/img/PMD-icon.png"/>
                    <img className="whh-icon" alt="WHH icon" src="/static/img/WHH-icon.png"/>
                    <div className="icons-group">
                        <div className="flood">
                            <a href={"/ff"} title={"flood analysis"}>
                                <img className="element" alt="Flood" src="/static/img/flood.png"/>
                            </a>
                            <div className="label">
                                <div className="flood-analysis">
                                    Flood
                                    <br/>
                                    Analysis
                                </div>
                            </div>

                        </div>
                        <div className="heat">
                            <a href={"/heat_analysis"} title={"heat analysis"}>
                                <img className="element" alt="Element" src="/static/img/heat.png"/>
                                <div className="label">
                                    <div className="heat-analysis">
                                        Heat
                                        <br/>
                                        Analysis
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="drought">
                            <a href={"/heat_analysis"} title={"heat analysis"}>
                                <img className="element" alt="Drought" src="/static/img/drought.png"/>
                                <div className="label">
                                    <div className="drought-analysis">
                                        Drought
                                        <br/>
                                        Analysis
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className={"sign-in-div"}>

                        <a href={"#"} onClick={handleClick}><Typography className="footer-text">Sign in to see your
                            request Status
                            <LoginIcon/>
                        </Typography></a>


                    </div>

                </div>
            </div>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{otpInput ? "Enter OTP" :" Enter Your Phone Number"}</DialogTitle>
                <DialogContent>
                    {otpInput ? <OTPInput onChangeOTP={onChangeOTP}/> : <><TextField
                        label="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                        <Box marginTop={2}>
                            <p>You will receive a message after the report is processed.</p>
                        </Box></>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    {!otpInput  && <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MainPage;
