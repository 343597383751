import React from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Define the data structure interface
interface LULCItem {
    value: string;
    area: number;
    unit: string;
    name: string;
}

interface ExposureData {
    population: number;
    lulc: LULCItem[];
    buildings: number;
}

interface ExposureDataTableProps {
    data: { [date: string]: ExposureData };
}

const ExposureDataTable: React.FC<ExposureDataTableProps> = ({ data }) => {
    return (
        <div>
            {Object.keys(data).map((date) => (
                <Accordion key={date}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${date}-content`}
                        id={`panel-${date}-header`}
                    >
                        <Typography>{`Date: ${date}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography><strong>Population:</strong> {data[date].population}</Typography>
                        <Typography><strong>Buildings:</strong> {data[date].buildings}</Typography>

                        <Typography variant="h6">LULC Data:</Typography>
                        <table style={{ width: '100%', fontSize: '12px', borderCollapse: 'collapse' }}>
                            <thead>
                            <tr>
                                <th style={{ textAlign: 'left', padding: '4px' }}>Name</th>
                                <th style={{ textAlign: 'left', padding: '4px' }}>Value (Area + Unit)</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data[date].lulc.map((lulcItem, index) => (
                                <tr key={index}>
                                    <td style={{ padding: '4px' }}>{lulcItem.name}</td>
                                    <td style={{ padding: '4px' }}>{lulcItem.area} {lulcItem.unit}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default ExposureDataTable;
