import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import {FloodInfoTable, IFloodInfoProps} from './FloodInfoTable';
import LayerSwitcherPaper from "damap/dist/esm/ol-map/components/LayerSwitcher/LayerSwitcherPaper";

interface IProps extends IFloodInfoProps {
    // basinName: string
    displayName: string
}
const FloodInfoTabs = (props: IProps) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Box>
            <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Flood Info Table" />
                <Tab label="Layer Switcher" />
            </Tabs>
            {activeTab === 0 && (
                <Box>
                    <h3>{props.displayName}</h3>
                    <FloodInfoTable
                        key={"flood-info-table"}
                        mapVM={props.mapVM}
                        affectedPopulation={props.affectedPopulation}
                        lulc={props.lulc}
                        buildingCounts={props.buildingCounts}
                    />
                </Box>
            )}
            {activeTab === 1 && (
                <Box>
                    <LayerSwitcherPaper mapVM={props.mapVM}/>
                </Box>
            )}
        </Box>
    );
};

export default FloodInfoTabs;