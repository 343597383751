import * as React from "react";

import {RefObject, useRef} from "react";

import {appSnackbarRef} from "../../App";
import {AppAPIs} from "../../Api";
// @ts-ignore
import TimeSlider, {IDateRange} from "damap/lib/ol-map/components/controls/TimeSlider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import MapView from "damap/dist/esm/ol-map/containers/MapView";
import TimeSliderControl from "damap/dist/esm/ol-map/components/controls/TimeSliderControl";
import MapContainer from "damap/dist/esm/ol-map/containers/MapContainer";
import FloodToolbar from "../components/FloodForecast/FloodToolbar";
import MapVM from "damap/dist/esm/ol-map/models/MapVM";

const timeSliderRef: RefObject<TimeSlider> = React.createRef()


export const mapViewRef: React.RefObject<MapView> = React.createRef();

const FloodForecast = () => {
    const mapUUID: string = "77d677084f3e11eebc0eacde48001122";
    const [dischargeDate, setDischargeDate] = React.useState<Date>(null)
    const [mapVM, setMapVM] = React.useState<MapVM>()
    const api: MapApi = new MapApi(appSnackbarRef)
    let slider: TimeSliderControl = null;
    // const mapVM = mapViewRef?.current?.getMapVM()
    React.useEffect(()=>{
        if(!mapVM){
            setMapVM(mapViewRef?.current?.getMapVM())
        }
    },[mapViewRef?.current?.getMapVM()])

    const onDateChange = (date: Date) => {
        const uuid: string = "3d070b54566111eeaaaeacde48001122";
        setDischargeDate(date)
        // @ts-ignore
        const daLayer: MVTLayer = mapViewRef?.current?.getMapVM().getDALayer(uuid)
        if (daLayer) {
            daLayer.updateTemporalData(date)
        }
    }
    const addTimeSlider = () => {
        const interval = setInterval(() => {
            const map = mapViewRef.current?.getMapVM().getMap();
            if (map && !slider) {
                // setMapVM(mapViewRef.current?.getMapVM());
                // const url = MapApi.getURL(AppAPIs.FF_DISCHARGE_DATE_RANGE)
                slider = new TimeSliderControl({
                    mapVM: mapViewRef.current?.getMapVM(),
                    timeSliderRef: timeSliderRef,
                    onDateChange: onDateChange
                });
                //@ts-ignore
                map.addControl(slider);
                clearInterval(interval);
            }
        }, 500);
    };
    React.useEffect(() => {
        addTimeSlider();
        api.get(AppAPIs.FF_DISCHARGE_DATE_RANGE, {}).then((payload) => {
            if (payload) {
                const s: IDateRange = {
                    minDate: new Date(Date.parse(payload.min_date)),
                    maxDate: new Date(Date.parse(payload.max_date))
                }
                setDischargeDate(s.maxDate)
                setTimeout(() => timeSliderRef?.current?.setDateRange(s), 500)
            }
        });

        setTimeout(() => mapViewRef?.current?.getMapVM()?.openLayerSwitcher(), 3000)
    }, [])
    // const updateStyleWithCenterAnchor = (layer) => {
    //     const currentStyleFunction = layer.getStyleFunction();
    //     const currentStyle = currentStyleFunction()[0]; // Assuming a single style
    //
    //     if (currentStyle.getImage() && currentStyle.getImage().getAnchor) {
    //         const newStyle = currentStyle.clone();
    //         newStyle.getImage().setAnchor([0.5, 0.5]);
    //         layer.setStyle(newStyle);
    //     }
    // };


    return (<>
        <MapContainer ref={mapViewRef} uuid={mapUUID} title={"Flood Forecast"}>
            {mapVM && <FloodToolbar mapVM={mapVM}/>}
            {/*<Button onClick={handleFloodInputs} variant={"outlined"} color={"inherit"}>Flood Analysis</Button>*/}
            &nbsp;&nbsp;
            <Box>
                <Typography>Discharge Date: {dischargeDate && dischargeDate.toLocaleDateString()}</Typography>
            </Box>


        </MapContainer>
    </>)
}

export default FloodForecast
