import React from "react";

// Define the styles for the table
const styles = {
    tableWrapper: {
        maxHeight: '100%', // Set the desired height for the scrollable area
        overflowY: 'auto' as 'auto',  // Enable vertical scrolling
        display: 'block' as 'block',
    },
    table: {
        borderCollapse: "collapse" as const,
        margin: "20",
        fontSize: "18px",
        width: "100%",
    },
    thead: {
        backgroundColor: "#215b4f",
        color: "#ffffff",
        position: 'sticky' as const,  // Make the header sticky
        top: 0,  // Stick to the top
        zIndex: 1,  // Ensure it stays on top of other elements
    },
    th: {
        padding: "12px 15px",
        textAlign: "center" as const,
    },
    td: {
        padding: "12px 15px",
    },
    tbodyTr: {
        borderBottom: "1px solid #dddddd",
    },
    tbodyTrEven: {
        backgroundColor: "#f3f3f3",
    },
    tbodyTrLast: {
        borderBottom: "2px solid #009879",
    },
    tbodyTrHover: {
        backgroundColor: "#f1f1f1",
    }
};

// Function to convert strings to sentence case
export const toSentenceCase = (str: string) => {
    return str.toLowerCase().replace(/(^\w)|(\s\w)/g, (match) => match.toUpperCase());
};

// Type definition for table row data
interface TableRowData {
    [key: string]: string | number | boolean;
    id?: string;
}

interface BoundaryStatsTableProps {
    data: TableRowData[];
}

const BoundaryStatsTable: React.FC<BoundaryStatsTableProps> = ({ data }) => {
    // Ensure the data array is not empty before rendering the table
    if (!data || data.length === 0) {
        return <div>No data available</div>;
    }

    return (
        <div style={styles.tableWrapper}>
            <table style={styles.table}>
                <thead style={styles.thead}>
                <tr>
                    {Object.keys(data[0])
                        .filter((key) => key.toLowerCase() !== "id")
                        .map((key) => (
                            <th key={key} style={styles.th}>
                                {toSentenceCase(key.replace("_", " "))}
                            </th>
                        ))}
                </tr>
                </thead>
                <tbody>
                {data.map((row, index) => (
                    <tr
                        key={row.id || index}
                        style={{
                            ...styles.tbodyTr,
                            ...(index % 2 === 0 ? styles.tbodyTrEven : {}),
                            ...(index === data.length - 1 ? styles.tbodyTrLast : {}),
                        }}
                        onMouseEnter={(e) =>
                            (e.currentTarget.style.backgroundColor =
                                styles.tbodyTrHover.backgroundColor)
                        }
                        onMouseLeave={(e) =>
                            (e.currentTarget.style.backgroundColor =
                                index % 2 === 0 ? styles.tbodyTrEven.backgroundColor || "" : "")
                        }
                    >
                        {Object.keys(row)
                            .filter((key) => key.toLowerCase() !== "id")
                            .map((key, index) => (
                                <td key={`${row.id || index}-${index}`} style={styles.td}>
                                    {row[key]}
                                </td>
                            ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default BoundaryStatsTable;
