import React, {useImperativeHandle, useState, forwardRef} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import OTPInput from "./otp/OTPInput";

import {AppAPIs} from "../../../Api";
import {appSnackbarRef} from "../../../App";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";

export interface OTPDialogHandle {
    openDialog: (request_id: string) => void;
    closeDialog: () => void;
}

interface IProps {
    api: MapApi
}

const OTPDialog = forwardRef<OTPDialogHandle, IProps>((props: IProps, ref) => {
    const [open, setOpen] = useState(false);
    const [otp, setOTP] = useState("");
    const [requestID, setRequestID] = useState<string>("");

    useImperativeHandle(ref, () => ({
        openDialog: (request_id: string) => {
            setOpen(true);
            setRequestID(request_id);
        },
        closeDialog: () => setOpen(false)
    }));

    const handleOTPChange = (enteredOTP: string) => {
        setOTP(enteredOTP);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        // const api = props.mapVM.getApi();
        try {
            const payload = await props.api.get(AppAPIs.DRM_HAZARD_CONFIRM_REQUEST, {
                request_id: requestID,
                otp: otp
            });
            if (payload?.success) {
                appSnackbarRef?.current?.show("Request registered successfully");
            } else {
                appSnackbarRef?.current?.show("Failed to register request");
            }
        } catch (error) {
            console.error("Error confirming request:", error);
            appSnackbarRef?.current?.show("An error occurred while registering the request");
        }
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Enter OTP</DialogTitle>
            <DialogContent>
                <OTPInput length={6} onChangeOTP={handleOTPChange}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default OTPDialog;
