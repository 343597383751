import React, { useImperativeHandle, forwardRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { toLonLat } from 'ol/proj.js';
import { Coordinate } from 'ol/coordinate';
export interface SMSDialogRef {
    openDialog: (coordinates: Coordinate) => void;
    closeDialog: () => void;
}

const SMSDialog = forwardRef<SMSDialogRef>((_, ref) => {
    const [open, setOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [coordinates, setCoordinates] = useState<Coordinate | null>(null);
    const [message, setMessage] = useState<string>("WHH Alert: Flooding reported. Click the link to navigate to the affected location: ")
    useImperativeHandle(ref, () => ({
        openDialog(coordinates: Coordinate) {
            setCoordinates(coordinates);
            setOpen(true);
        },
        closeDialog() {
            setOpen(false);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const getGoogleMapURL = () => {
        if (coordinates) {
            const [x, y] = coordinates;
            const lonLat = toLonLat([x, y]);
            const googleMapsUrl = `https://www.google.com/maps?q=${lonLat[1]},${lonLat[0]}`;
            // Logic to send SMS (use any SMS service API)
            console.log(`Sending SMS to ${phoneNumber} with URL: ${googleMapsUrl}`);
            handleClose();
            return googleMapsUrl
        }
    };

    const modifyPhoneNo = (modifiedPhoneNumber: string) => {
        // Remove any non-numeric characters
        const cleanedNumber = modifiedPhoneNumber.replace(/\D/g, '');

        // Remove leading zeros
        const cleanedNumberWithoutZeros = cleanedNumber.replace(/^0+/, '');

        // Remove '+' sign if present
        const cleanedNumberWithoutPlus = cleanedNumberWithoutZeros.replace(/^\+/, '');

        // Add country code '92' if missing
        return cleanedNumberWithoutPlus.startsWith('92') ?
            cleanedNumberWithoutPlus :
            '92' + cleanedNumberWithoutPlus;

    }


    const handleSendSMS = () => {
        if (phoneNumber && coordinates) {
            const pno = modifyPhoneNo(phoneNumber);
            alert("Sending SMS to " + pno);
            const msgURL = getGoogleMapURL();
            const sms_token = process.env.REACT_APP_SMS_TOKEN
            const sms_api_secret = process.env.REACT_APP_SMS_API_SECRET
            const values = {
                "api_token": sms_token,
                "api_secret": sms_api_secret,
                "to": pno,
                "from": 'TheSptlCdrs',
                "message": message + " " + msgURL

            }

            // alert(JSON.stringify(values))
            const url: string = "https://lifetimesms.com/json"
            //@ts-ignore
            const queryString = new URLSearchParams(values).toString();
            const cURL = `${url}?${queryString}`
            fetch(`${url}?${queryString}`).then((response) => {
                // Check if the request was successful (status code 200)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                // Parse the JSON in the response
                return response.text();
            }).then((data) => {
                console.log("successful", data)

            }).catch(error => {
                console.error("Fetch error:", error);

            });
            // const win = window.open(cURL, '_blank')
            // setTimeout(()=>win.close(),1000);
            handleClose();
        }

    }


    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Send Location</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the phone number to send the location as a Google Maps link.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Phone Number"
                    type="tel"
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSendSMS} color="primary">
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default SMSDialog;
