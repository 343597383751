import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button} from '@mui/material';
import {AppAPIs} from "../../../Api";
import {appSnackbarRef} from "../../../App";
import ExposureDataTable from "./ExposureDataTable";

const ExposureAnalysisDialogBox = ({open, onClose, requestId, lon, lat, mapVM}) => {
    const [buffer, setBuffer] = useState<number>(1000); // Default buffer is 1000

    const handleBufferChange = (event) => {
        setBuffer(event.target.value);
    };

    const handleSubmit = () => {
        // onSubmit({ x, y, buffer: buffer});
        // const { x, y, buffer } = data;

        // Send the coordinates and buffer to the server
        mapVM.getApi().get(AppAPIs.DRM_ELEMENT_AT_RISK, {
            request_id: requestId,
            lon,
            lat,
            buffer_in_meter: buffer,
        })
            .then(payload => {

                // appSnackbarRef.current?.show("Request submitted successfully");
                // You can update the dialog to show the response here in the future
                if (payload) {
                    console.log(payload)
                    const rightDrawer = mapVM.getRightDrawerRef();
                    const contents = <React.Fragment>
                        <ExposureDataTable data={payload} />
                    </React.Fragment>
                    rightDrawer?.current?.addContents("Flood Impact", contents);
                    rightDrawer?.current?.openDrawer();
                }
            })
            .catch(error => {
                console.error("Error sending buffer request:", error);
                appSnackbarRef.current?.show("Failed to submit request");
            });
        onClose(); // Close the dialog after submission
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Element At Risk</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please enter the buffer distance (in meters) to access element at risk around the point at
                    coordinates ({lon.toFixed(3)}, {lat.toFixed(3)}).
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Buffer (meters)"
                    type="number"
                    fullWidth
                    value={buffer}
                    onChange={handleBufferChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} variant="contained">Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExposureAnalysisDialogBox;
