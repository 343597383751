import React from "react";
import { Toolbar, Typography, Button, Menu, MenuItem } from "@mui/material";
import { useNavigate, useParams } from 'react-router-dom'; // For navigation
import { appSnackbarRef } from "../../../App";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import "./css/risk_assessment.css";
import {AppAPIs} from "../../../Api";

interface IData {
    id: string;
    is_number_verified: boolean;
    is_process_completed: boolean;
}

const RiskAssessmentRequestTable = () => {
    const { phoneNo } = useParams();
    const [data, setData] = React.useState<IData[]>([]);
    const [selectedRow, setSelectedRow] = React.useState<string | null>(null); // To store the selected row ID
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null); // For dropdown
    const mapApi = new MapApi(appSnackbarRef);
    const navigate = useNavigate();

    React.useEffect(() => {
        const url = MapApi.getURL(AppAPIs.DRM_HAZARD_REQUEST_LIST, { phone_no: phoneNo })
        mapApi.get(AppAPIs.DRM_HAZARD_REQUEST_LIST, { phone_no: phoneNo }).then((payload) => {
            // console.log("list", payload)
            if (payload) {
                setData(payload.data);
            }
        });
    }, [phoneNo]);

    // Open the dropdown menu
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // Close the dropdown menu
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // Handle View Map action
    const handleViewMap = () => {
        if (selectedRow) {
            navigate(`/rar/${selectedRow}`);
        } else {
            alert("Please select a row first");
        }
        handleMenuClose();
    };

    // Handle Delete Row action
    const handleDeleteRow = async () => {
        if (selectedRow) {
            try {
                // await mapApi.delete(`${AppAPIs.DRM_HAZARD_REQUEST}/${selectedRow}`);
                // Filter out the deleted row from the data
                setData((prevData) => prevData.filter((row) => row.id !== selectedRow));
                setSelectedRow(null); // Reset selected row after deletion
            } catch (error) {
                alert("Failed to delete the row.");
            }
        } else {
            alert("Please select a row first");
        }
        handleMenuClose();
    };

    // Handle row selection
    const handleRowClick = (id: string) => {
        setSelectedRow(id); // Set the selected row and keep it highlighted
    };

    return (
        <div className="common-container">
            {/* MUI Toolbar */}
            <Toolbar sx={{ backgroundColor: "#f5f5f5", padding: '10px', width: '100%' }}>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Risk Assessment Requests
                </Typography>
                <Button variant="contained" color="primary" onClick={handleMenuClick}>
                    Actions
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleViewMap}>View Map</MenuItem>
                    <MenuItem onClick={handleDeleteRow}>Delete Row</MenuItem>
                </Menu>
            </Toolbar>

            {/* Table */}
            <table className="colorful-table">
                <thead>
                <tr>
                    <th>Request ID</th>
                    <th>Is Number Verified</th>
                    <th>Is Process Completed</th>
                </tr>
                </thead>
                <tbody>
                {data.map((d: IData) => (
                    <tr
                        key={d.id}
                        className={selectedRow === d.id ? "selected-row" : ""}
                        onClick={() => handleRowClick(d.id)}
                    >
                        <td>{d.id}</td>
                        <td>{d.is_number_verified ? "Yes" : "No"}</td>
                        <td>{d.is_process_completed ? "Yes" : "No"}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default RiskAssessmentRequestTable;
